<template>
	<div class="single-view tool-geoip">
		<div class="tool-box">
			<h1>{{ ii('IP_GEOLOCATION') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-area
						v-model="app.query.ip"
						ref="tf"
						:placeholder="ii('INPUT_IP_ADDRESSES_ONE_PER_LINE')"
						:error="error"
					></s-text-area>

					<div class="submit-box">
						<s-btn icon="globe" :loading="isLoading" @click="refreshQuery">{{ ii('SUBMIT') }}</s-btn>
					</div>
				</div>
				<div v-if="rows" class="general-tbl-outer">
					<table class="general-tbl">
						<thead>
						<tr>
							<th>
								<s-tbl-sort v-model="sort" sort-key="num">#</s-tbl-sort>
							</th>
							<th>
								<s-tbl-sort v-model="sort" sort-key="ip">{{ ii('IP_ADDRESS') }}</s-tbl-sort>
							</th>
							<th>
								<s-tbl-sort v-model="sort" sort-key="countryName">{{ ii('COUNTRY') }}</s-tbl-sort>
							</th>
							<th>
								<s-tbl-sort v-model="sort" sort-key="city">{{ ii('CITY') }}</s-tbl-sort>
							</th>
							<th>
								<s-tbl-sort v-model="sort" sort-key="isp">{{ ii('ISP') }}</s-tbl-sort>
							</th>
							<th>
								<s-tbl-sort v-model="sort" sort-key="timezone">{{ ii('TIMEZONE') }}</s-tbl-sort>
							</th>
							<th>
								<s-tbl-sort v-model="sort" sort-key="coordinates">{{ ii('COORDINATES') }}</s-tbl-sort>
							</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="row in sortedRows">
							<td class="text-center">
								{{ row.num }}
							</td>
							<td>
								{{ row.ip }}
							</td>
							<td>
								<s-flag :cc="row.countryCode"></s-flag>
								{{ row.countryName }}
							</td>
							<td>
								{{ row.city }}
							</td>
							<td>
								{{ row.isp }}
							</td>
							<td>
								{{ row.timezone }}
							</td>
							<td>
								{{ row.coordinates }}
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
.tool-geoip {
	.submit-box {
		padding: 5px 0 0;
		text-align: center;
	}

	.general-tbl-outer {
		margin: 20px 0 100px;
	}
}
</style>

<script>
	export default {
		data() {
			return {
				rows: null,
				isLoading: false,
				error: null,
				sort: null
			};
		},
		computed: {
			sortedRows() {
				if (!this.sort) return this.rows;

				let dir = 1;
				if (this.sort.charAt(0) === '-') {
					dir = -1;
				}
				let key = this.sort.replace(/^-/, '');

				this.rows.sort((a, b) => {
					if (a[key] > b[key]) return dir;
					if (a[key] < b[key]) return -dir;
					return 0;
				});

				return this.rows;
			}
		},
		methods: {
			async getInfo() {
				this.error = null;

				let ip = this.app.query.ip;

				if (ip) {
					this.hit('geoip_enter', ip.slice(0, 50));
				}

				this.isLoading = true;
				this.rows = await this.post('GeoIP.getInfo', ip);
				this.isLoading = false;
			}
		},
		watch: {
			'$route'() {
				this.getInfo();
			}
		},
		mounted() {
			if (!this.app.isMob) {
				this.$refs.tf.focus();
			}
			this.getInfo();
		}
	}
</script>
